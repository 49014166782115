/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import './layout.css'
import "../styles/index.css"
import '../fonts/fonts.css'
import Logo from "./logo"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
    
        <main>{children}</main>

        <footer className="bg-gray-900 p-20 text-center">
        <div className="w-32 block mx-auto">
        <Logo fill={'#999'}/>
        </div>
        <span className="text-gray-500 block py-4 text-sm max-w-sm mx-auto">{data.site.siteMetadata.description}</span>
        <a href="https://twitter.com/dkrasniy" target="_blank" className="hover:text-gray-100 text-gray-700 block text-sm">Built with &hearts; in California</a>

      </footer>


        

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
