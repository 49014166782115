import React from "react"

const Logo = ({ fill }) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 311.43 115.18">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path fill={fill ? fill : '#222'} d="M0,52.53H14.61L30.8,78.58,47,52.53H61.6v61.6H48.13V73.91L30.8,100.22h-.35l-17.16-26v39.95H0Z" />
          <path fill={fill ? fill : '#222'} d="M68.73,105.15l8-9.59c5.54,4.58,11.35,7.48,18.39,7.48,5.54,0,8.89-2.2,8.89-5.81v-.17c0-3.44-2.12-5.2-12.41-7.84-12.41-3.16-20.42-6.6-20.42-18.83v-.17c0-11.18,9-18.57,21.56-18.57a35.63,35.63,0,0,1,22.88,7.83l-7,10.21c-5.46-3.79-10.82-6.07-16-6.07S84.66,66,84.66,69v.18c0,4,2.64,5.37,13.28,8.1,12.5,3.25,19.54,7.74,19.54,18.48v.17c0,12.23-9.33,19.1-22.62,19.1A39.2,39.2,0,0,1,68.73,105.15Z" />
          <path fill={fill ? fill : '#222'} d="M125.84,52.53h24c19.36,0,32.74,13.29,32.74,30.62v.18c0,17.33-13.38,30.8-32.74,30.8h-24Zm24,49.37c11.09,0,18.57-7.48,18.57-18.4v-.17c0-10.91-7.48-18.57-18.57-18.57H139.39V101.9Z" />
          <path fill={fill ? fill : '#222'} d="M191.22,52.53h12.5l28.86,37.93V52.53H246v61.6H234.43L204.6,75v39.16H191.22Z" />
          <path fill={fill ? fill : '#222'} d="M254.58,83.5v-.17c0-17.51,13.2-31.86,32.12-31.86,11.62,0,18.57,3.87,24.29,9.51l-8.62,9.94c-4.76-4.31-9.6-7-15.76-7-10.38,0-17.86,8.62-17.86,19.18v.18c0,10.56,7.3,19.36,17.86,19.36,7,0,11.36-2.82,16.2-7.22l8.62,8.71c-6.34,6.78-13.38,11-25.26,11C268.05,115.18,254.58,101.19,254.58,83.5Z" />
          <circle fill="#e8bb25" cx="22.37" cy="18.9" r="18.9" />
          <circle fill="#e3761c" cx="75.89" cy="18.9" r="18.9" />
          <circle fill="#da383a" cx="129.41" cy="18.9" r="18.9" />
          <circle fill="#9d73ec" cx="182.92" cy="18.9" r="18.9" />
          <circle fill="#3d91ec" cx="236.44" cy="18.9" r="18.9" />
          <circle fill="#44a675" cx="289.96" cy="18.9" r="18.9" />
        </g>
      </g>
    </svg>
  </>
)

export default Logo