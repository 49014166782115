import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../components/logo"

const  TwitterIconBS = (<svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"   className="w-5 h-5 fill-current"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" class=""></path></svg>)
 

const Header = ({ siteTitle }) => (

  <header role="banner" className="bg-gray-900 relative shadow-lg">
  <div className="mx-auto container-md-up container p-4">
  
    <Link to="/" className="font-semibold w-32 md:w-40 order-none md:p-2 mx-auto block">
    <Logo fill="#fff"/>
    </Link>
  

  </div>

  <nav role="navigation" className="w-full flex text-center md:mt-0 md:text-left bg-white">
      <div  className={`items-center max-w-3xl min-w-3xl mx-auto overflow-x-auto w-full camber flex flex-wrap md:flex-no-wrap`}>
        <ul role="menu" className="order-2 md:order-0 list-reset md:flex flex-1 items-center whitespace-no-wrap text-gray-700">
          <li  className="pl-0 px-3 mb-0 inline-block font-semibold  cursor-pointer hover:cursor-pointer hover:text-black  text-sm">
          <Link  to={'/'} role="menuitem" activeClassName="text-black active-menu" className="block py-3 border-b-2 border-t-2  border-transparent">
            Home
          </Link>
          </li>
          <li  className="px-3 mb-0 inline-block font-semibold hover:text-black cursor-pointer text-sm">
         
          <Link to={'/tags/bernie-sanders/'} activeClassName="text-black active-menu" role="menuitem" className="block py-3 border-b-2 border-t-2 border-transparent">
            Bernie Sanders
          </Link>
          </li>
          <li  className="px-3 mb-0 inline-block font-semibold hover:text-black cursor-pointer text-sm">
         
          <Link to={'/tags/mayor-pete/'} activeClassName="text-black active-menu" role="menuitem" className="cursor-pointer  hover:cursor-pointer  block py-3 border-b-2 border-t-2  border-transparent">
            Mayor Pete
          </Link>
          </li>
          <li className="px-3 mb-0 inline-block font-semibold hover:text-black cursor-pointer text-sm">
         
          <Link to={'/tags/tom-perez/'} activeClassName="text-black active-menu" role="menuitem" className="cursor-pointer  hover:cursor-pointer block py-3 border-b-2 border-t-2  border-transparent">
            Tom Perez
          </Link>
          </li>
        
        </ul>

    <div className="hidden md:flex w-full md:w-auto order-0 md:order-2 items-center"> <span class="italic text-gray-400 text-xs block border-r-2 pr-2 mr-2">{new Date().toDateString()}</span>  <a className="text-gray-700 block" href="https://twitter.com/MSDNCTV" rel="noopener noreferrer" target="_blank"> {TwitterIconBS}</a>
   
    </div>

      </div>

    </nav>
</header>


)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

